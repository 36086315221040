
import Vue from "vue";
import KCrudTable, {
  CrudTableHeader,
} from "@/modules/crudTable/components/KCrudTable.vue";
import { User, userDestroy, userIndex } from "../api/user";
import dayjs from "@/plugins/dayjs";

interface ComponentData {
  headers: CrudTableHeader[];
}

export default Vue.extend({
  name: "UserTable",
  components: { KCrudTable },
  data: (): ComponentData => ({
    headers: [
      { value: "name" },
      { value: "email" },
      { value: "clientName" },
      {
        value: "lastLoginDate",
        valueFormatter: (date) => dayjs(date as string).format("LLL"),
      },
    ],
  }),
  methods: {
    async userDestroy(item: User) {
      await userDestroy(item.id);
    },
    userIndex: userIndex,
    handleEdit(item: any) {
      this.$router.push({
        name: "user.edit",
        params: { id: item.id },
      });
    },
  },
});
